import React from 'react'
import { useParams } from 'react-router-dom'
import { QueuerCard } from '../components/Queuer'
import { ServiceFooter } from '../components/Service'

import { useFetchQueuerData } from '../hooks'
import NotFoundPage from './404'

export const QueuerPage = () => {
  const { qid } = useParams()

  const { isLoading, isError, queuer } = useFetchQueuerData({
    id: qid,
  })

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isError === true || !queuer) {
    return <NotFoundPage />
  }

  return (
    <>
      <QueuerCard queuer={queuer} />
      <ServiceFooter />
    </>
  )
}
