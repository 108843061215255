import React from 'react'
import { ServiceFooter } from './ServiceFooter'
import { ServiceNavBar } from './ServiceNavBar'

export const ServiceLayout = props => {
  return (
    <>
      <ServiceNavBar {...props} />
      <section className="is-min-half-height">
        <div>{props.children}</div>
      </section>
      <ServiceFooter />
    </>
  )
}
