import React from 'react'

export const ServiceFooter = () => {
  return (
    <footer className="footer is-backgroundless">
      <div className="content has-text-right">
        <div className="heading tags has-addons">
          <span className="tag has-border-grey is-size-7">Powered by {' '}</span>
          <span className="tag is-primary">            
            <a
              className="has-text-white has-text-weight-bold is-size-7"
              href="https://www.qboy.com.au"
              title="qboy.com.au"
            >
              qboy
            </a>
          </span>
        </div>
      </div>
    </footer>
  )
}
