import React, { useState } from 'react'
import { Detector } from 'react-detect-offline'
import { ImWarning } from 'react-icons/im'

export const RefreshOffline = props => {
  const [, setValue] = useState()

  const refresh = () => {
    setValue({})
  }

  return (
    <Detector
      render={({ online }) => {
        if (online) {
          return props.children
        } else {
          setTimeout(() => {
            refresh()
          }, 2000)
          return (
            <div
              className="notification is-warning is-light p-4 help"
              role="alert"
            >
              <span className="icon">
                <ImWarning />
              </span>{' '}
              You are disconnected, trying to connect again...
            </div>
          )
        }
      }}
    />
  )
}

export default RefreshOffline
