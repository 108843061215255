import React from 'react'
import Footer from '../Footer'
import NavBar from '../NavBar'

export const Layout = (props) => {
  return (
    <>
      <NavBar />
      <div>{props.children}</div>
      <Footer/>
    </>
  )
}
