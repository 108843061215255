import React from 'react'
import { Link } from 'react-router-dom'
import ReactTimeAgo from 'react-time-ago'

export const QueueTable = props => {
  const { queueItems } = props

  return (
    <section className="p-2 table-container has-text-centered">
      <div className="has-font-weight-bold is-size-7 subtitle has-text-centered heading">
        Waiting List {queueItems.length}
      </div>

      <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
        <thead>
          <tr style={styles.tableHeaderBackground}>
            <th className="heading is-tablecell has-text-centered">
              Name / ID
            </th>
            <th className="heading is-tablecell has-text-centered">Status</th>
          </tr>
        </thead>
        <tbody>
          {queueItems.map((item, index) => (
            <QueuerRow key={index} item={item} />
          ))}
        </tbody>
      </table>
    </section>
  )
}

const QueuerRow = ({ item }) => {
  let statusLabel, statusTagStyle, lastUpdated

  if (item.status === 1) {
    statusLabel = 'WAITING'
    statusTagStyle = 'is-warning'
    lastUpdated = item.queuedOn
  } else {
    statusLabel = 'READY'
    statusTagStyle = 'is-success'
    lastUpdated = item.readyOn
  }

  return (
    <tr>
      <td>
        <span className="is-size-4">
          <Link to={`/qid/${item.id}`}>{item.queuer}</Link>
        </span>
      </td>
      <td className="has-text-centered">
        <span className={`tag ${statusTagStyle}`}>{statusLabel}</span>
        <p className="subtitle is-size-7 has-text-grey mt-1">
          <ReactTimeAgo date={lastUpdated} timeStyle="twitter" />
        </p>
      </td>
    </tr>
  )
}

const styles = {
  tableHeaderBackground: {
    background: '#fafaf7',
  },
}
