import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer
      className="footer is-backgroundless"
      style={{
        zIndex: 1000,
      }}
    >
      <div className="content has-text-centered">
        <a href="mailto:admin@locoomago.com" rel="noreferrer" target="_blank" title="Contact us">
          <span>Contact us</span>
        </a>
        <Link to="/terms">Terms</Link>
        <Link to="/privacy">Privacy Policy</Link>
      </div>
    </footer>
  )
}

export default Footer
