import React from 'react'
import { Layout } from '../components/Layout/Layout'
import { Search } from '../components/Search'

const HomePage = () => {
  return (
    <>
      <Layout>
        <section className="hero is-bold is-medium">
          <div className="hero-body px-2">
            <div className="container">
              <Search/>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default HomePage
