import React from 'react'
import { useParams } from 'react-router-dom'
import OnPageVisible from '../components/OnPageVisible/OnPageVisible'
import { QueueItemsData } from '../components/QueueItemsData/QueueItemsData'
import RefreshOffline from '../components/RefreshOffline/RefreshOffline'
import { ServiceLayout } from '../components/Service'

import { useFetchServiceQueueData } from '../hooks'
import NotFoundPage from './404'

export const QueueListPage = () => {
  const { sid } = useParams()

  const { isLoading, isError, serviceQueue } = useFetchServiceQueueData({
    sid,
  })

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isError === true) {
    return <NotFoundPage />
  }
  if (serviceQueue) {
    return (
      <ServiceLayout service={serviceQueue}>
        <OnPageVisible>
          <RefreshOffline>
            <QueueItemsData serviceQueueID={serviceQueue.id} />
          </RefreshOffline>
        </OnPageVisible>
      </ServiceLayout>
    )
  }

  return <NotFoundPage />
}
