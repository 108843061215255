import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const NavBar = () => {
  const [isActive, setIsActive] = useState(false)
  const toggleNavbar = () => setIsActive(!isActive)

  return (
    <nav
      className="navbar is-fixed-top has-border-bottom-grey"
      style={{ zIndex: 800 }}
      aria-label="navigation"
      role="navigation"
    >
      <div className="container">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item">
            <span className="logo title is-4">QBoy</span>
          </Link>

          <div
            role="button"
            className={`navbar-burger burger ${isActive ? 'is-active' : ''}`}
            aria-expanded="false"
            data-target="navMenu"
            onClick={toggleNavbar}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </div>
        </div>

        <div
          className={`navbar-menu ${isActive ? 'is-active' : ''}`}
          id="navMenu"
        >
          <div className="navbar-end">
            <a
              href="https://www.qboy.com.au"
              rel="noreferrer"
              target="_blank"
              title="business"
              className="navbar-item"
            >
              <span>Business</span>
            </a>

            <div className="navbar-item">
              <div className="field is-grouped">
                <p className="control">
                  <Link className="button is-primary is-outlined" to="/contact">
                    Contact Us
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default NavBar
