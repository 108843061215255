import React, { useState } from 'react'
import { AiOutlineFullscreen } from 'react-icons/ai'

export const ServiceNavBar = props => {
  const { service } = props

  const [isActive, setIsActive] = useState(false)
  const toggleNavbar = () => setIsActive(!isActive)

  return (
    <>
      <nav className="navbar is-fixed-top is-borderless">
        <div style={styles.navBarContainer}>
          <div className="navbar-item">
            <AiOutlineFullscreen
              onClick={() => console.log('fullscreen clicked')}
            />
          </div>
        </div>
      </nav>

      <h1 className="logo title is-4 has-text-centered">{service.business.name}</h1>
      <h2 className="logo subtitle is-6 has-text-centered">{service.name}</h2>
    </>
  )
}

const styles = {
  navBarContainer: {
    display: 'flex',
    flexGrow: '1',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}
