import { useEffect, useReducer } from 'react'
import { API, graphqlOperation } from 'aws-amplify'

import {
  FETCH_DATA_INIT,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_FAILURE
} from './constants'

const serviceQueueByCode = /* GraphQL */ `
  query ServiceQueueByCode($code: String!) {
    serviceQueueByCode(code: $code, limit: 1) {
      items {
        id
        name
        code
        business {
          name
        }
      }
    }
  }
`

const fetchReducer = (state, action) => {
  switch (action.type) {
    case FETCH_DATA_INIT:
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        serviceQueue: action.payload.serviceQueue,
      }
      case FETCH_DATA_FAILURE:
        return {
          ...state,
          isLoading: false,
          isError: true
        }      
    default:
      throw new Error()
  }
}

const initialState = {
  isLoading: true,
  isError: false,
  serviceQueue: {}
}

export const useFetchServiceQueueData = ({ sid }) => {
  const [state, dispatch] = useReducer(fetchReducer, initialState)

  useEffect(() => {
    let isMounted = true

    const fetch = async () => {
      if (isMounted) {
        dispatch({ type: FETCH_DATA_INIT })
      }

      try {
        const input = { code: sid }
        const result = await API.graphql(
          graphqlOperation(serviceQueueByCode, input)
        )
        dispatch({
          type: FETCH_DATA_SUCCESS,
          payload: {
            serviceQueue: result.data.serviceQueueByCode.items[0],
          },
        })
      } catch (error) {
        if (isMounted) {
          console.error(error)
          dispatch({ type: FETCH_DATA_FAILURE })
        }
      }
    }

    fetch()

    return () => {
      isMounted = false
    }
  }, [sid])

  return state
}
