import React from 'react'
import { useFetchQueueItemsData } from '../../hooks'
import { QueueTable } from '../QueueTable'

export const QueueItemsData = props => {
  const { serviceQueueID } = props

  const { isLoading, queueItems } = useFetchQueueItemsData({
    serviceQueueID,
  })

  if (isLoading) {
    return <div>Loading...</div>
  }

  return <QueueTable queueItems={queueItems}/>
}
