import { useEffect, useReducer } from 'react'
import { API, graphqlOperation } from 'aws-amplify'

import {
  FETCH_DATA_INIT,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_FAILURE,
  ON_UPDATE,
} from './constants'

const getQueueItem = /* GraphQL */ `
  query getQueueItem($id: ID!) {
    getQueueItem(id: $id) {    
      status
      queuedOn
      readyOn
      collectedOn
    }
  }
`

const onUpdateQueueItemById = /* GraphQL */ `
  subscription OnUpdateQueueItemById($id: ID) {
    onUpdateQueueItemByID(id: $id) {
      status
      queuedOn
      readyOn
      collectedOn
    }
  }
`

const fetchReducer = (state, action) => {
  switch (action.type) {
    case FETCH_DATA_INIT:
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        queuerStatus: action.payload.queuerStatus,
      }
    case ON_UPDATE: {
      const updated = {
        ...state.queuerStatus,
        ...action.payload
      }

      return { ...state, queuerStatus: updated }
    }

    default:
      throw new Error()
  }
}

const initialState = {
  isLoading: true,
  isError: false,
  queuerStatus: {},
}

export const useFetchQueuerStatusData = ({ id }) => {
  const [state, dispatch] = useReducer(fetchReducer, initialState)

  useEffect(() => {
    let isMounted = true

    const fetch = async () => {
      if (isMounted) {
        dispatch({ type: FETCH_DATA_INIT })
      }

      try {
        const input = { id }
        const result = await API.graphql(graphqlOperation(getQueueItem, input))
        dispatch({
          type: FETCH_DATA_SUCCESS,
          payload: {
            queuerStatus: result.data.getQueueItem,
          },
        })
      } catch (error) {
        if (isMounted) {
          console.error(error)
          dispatch({ type: FETCH_DATA_FAILURE })
        }
      }
    }

    fetch()

    return () => {
      isMounted = false
    }
  }, [id])

  useEffect(() => {
    let subscriber

    const subscribeOnUpdateQueuer = async () => {
      const subscription = await API.graphql(
        graphqlOperation(onUpdateQueueItemById, { id })
      )

      subscriber = await subscription.subscribe({
        next: data => {
          dispatch({
            type: ON_UPDATE,
            payload: data.value.data.onUpdateQueueItemByID,
          })
        },
        error: () => {
          subscriber?.unsubscribe()
          subscribeOnUpdateQueuer()
        },
      })
    }

    subscribeOnUpdateQueuer()

    return () => subscriber?.unsubscribe()
  }, [id])

  return state
}
