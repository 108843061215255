import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import { Search } from '../components/Search'

const NotFoundPage = () => {
  return (
    <div id="layout-wrapper">
      <NavBar />

      <div id="content-wrapper">
        <section className="section py-4">
          <div className="container has-text-centered">
            <div className="columns is-centered">
              <div className="column is-7">
                <h1 className="title is-1">404</h1>
                <p className="subtitle is-3">Not Found</p>
              </div>
            </div>
            
            <Search/>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default NotFoundPage;
