import { useEffect, useReducer } from "react";
import { API, graphqlOperation } from "aws-amplify";

import {
  FETCH_DATA_INIT,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_FAILURE,
  ON_CREATE,
  ON_UPDATE,
} from "./constants";
import { STATUS_READY } from "../utils/status";

const listServiceQueuesByServiceQueueId = /* GraphQL */ `
  query listServiceQueuesByServiceQueueId($serviceQueueID: ID!) {
    listQueueItems(
      filter: {
        queueItemQueueId: { eq: $serviceQueueID }
        and: { status: { lt: 3 } }
      }
    ) {
      items {
        id
        code
        queuer
        status        
        note
        queuedOn
        readyOn
        collectedOn
      }
    }
  }
`;


const onCreateQueueItemByServiceQueueId = /* GraphQL */ `
  subscription OnCreateQueueItemByServiceQueueId($serviceQueueID: ID) {
    onCreateQueueItemByServiceQueueID(queueItemQueueId: $serviceQueueID) {
      id
      code
      queuer
      status
      note
      queuedOn
      readyOn
      collectedOn
    }
  }
`;

const onUpdateQueueItemByServiceQueueId = /* GraphQL */ `
  subscription OnUpdateQueueItemByServiceQueueId($serviceQueueID: ID) {
    onUpdateQueueItemByServiceQueueID(queueItemQueueId: $serviceQueueID) {
      id
      code
      queuer
      status
      note
      queuedOn
      readyOn
      collectedOn
    }
  }
`;

const fetchReducer = (state, action) => {
  switch (action.type) {
    case FETCH_DATA_INIT:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        queueItems: action.payload.queueItems
      };
    case ON_CREATE:
      return {
        ...state,
        queueItems: [...state.queueItems, action.payload]
      };
    case ON_UPDATE: {
      const index = state.queueItems.findIndex(
        (item) => item.id === action.payload.id
      );
      const newArray = [...state.queueItems];

      if (action.payload.status === STATUS_READY) {
        newArray[index] = action.payload;
      } else {
        newArray.splice(index, 1);
      }
      return { ...state, queueItems: newArray };
    }

    default:
      throw new Error();
  }
};

const initialState = {
  isLoading: true,
  isError: false,
  queueItems: []
};

export const useFetchQueueItemsData = ({ serviceQueueID }) => {
  const [state, dispatch] = useReducer(fetchReducer, initialState);

  useEffect(() => {
    let isMounted = true;

    const fetch = async () => {
      if (isMounted) {
        dispatch({ type: FETCH_DATA_INIT });
      }

      try {
        const input = { serviceQueueID };
        const result = await API.graphql(
          graphqlOperation(listServiceQueuesByServiceQueueId, input)
        );

        dispatch({
          type: FETCH_DATA_SUCCESS,
          payload: {
            queueItems: result.data.listQueueItems.items
          },
        });
      } catch (error) {
        if (isMounted) {
          console.error(error)
          dispatch({ type: FETCH_DATA_FAILURE });
        }
      }
    };

    fetch();

    return () => {
      isMounted = false;
    };
  }, [serviceQueueID]);

  useEffect(() => {
    let subscriber;

    const subscribeOnCreateQueueItem = async () => {
      const subscription = await API.graphql(
        graphqlOperation(onCreateQueueItemByServiceQueueId, { serviceQueueID })
      );

      subscriber = await subscription.subscribe({
        next: (data) => {
          dispatch({
            type: ON_CREATE,
            payload: data.value.data.onCreateQueueItemByServiceQueueID,
          });
        },
        error: () => {
          subscriber?.unsubscribe();
          subscribeOnCreateQueueItem();
        },
      });
    };

    subscribeOnCreateQueueItem();

    return () => subscriber?.unsubscribe();
  }, [serviceQueueID]);

  useEffect(() => {
    let subscriber;

    const subscribeOnUpdateQueueItem = async () => {
      const subscription = await API.graphql(
        graphqlOperation(onUpdateQueueItemByServiceQueueId, { serviceQueueID })
      );

      subscriber = await subscription.subscribe({
        next: (data) => {
          dispatch({
            type: ON_UPDATE,
            payload: data.value.data.onUpdateQueueItemByServiceQueueID,
          });
        },
        error: () => {
          subscriber?.unsubscribe();
          subscribeOnUpdateQueueItem();
        },
      });
    };

    subscribeOnUpdateQueueItem();

    return () => subscriber?.unsubscribe();
  }, [serviceQueueID]);

  return state;
};
