import { useEffect, useReducer } from 'react'
import { API, graphqlOperation } from 'aws-amplify'

import {
  FETCH_DATA_INIT,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_FAILURE
} from './constants'

const getQueueItem = /* GraphQL */ `
  query getQueueItem($id: ID!) {
    getQueueItem(id: $id) {
      id
      queuer
      status
      queuedOn
      readyOn
      collectedOn
      queueItemQueueId
      queue {
        id
        name
        code
        business {
          name
        }
      }
    }
  }
`

const fetchReducer = (state, action) => {
  switch (action.type) {
    case FETCH_DATA_INIT:
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        queuer: action.payload.queuer,
      }
    default:
      throw new Error()
  }
}

const initialState = {
  isLoading: true,
  isError: false,
  queuer: {},
}

export const useFetchQueuerData = ({ id }) => {
  const [state, dispatch] = useReducer(fetchReducer, initialState)

  useEffect(() => {
    let isMounted = true

    const fetch = async () => {
      if (isMounted) {
        dispatch({ type: FETCH_DATA_INIT })
      }

      try {
        const input = { id }
        const result = await API.graphql(graphqlOperation(getQueueItem, input))

        dispatch({
          type: FETCH_DATA_SUCCESS,
          payload: {
            queuer: result.data.getQueueItem,
          },
        })
      } catch (error) {
        if (isMounted) {
          console.error(error)
          dispatch({ type: FETCH_DATA_FAILURE })
        }
      }
    }

    fetch()

    return () => {
      isMounted = false
    }
  }, [id])

  return state
}
