import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import QboyImg from '../../assets/img/qboy.png'

export const Search = () => {
  const [sid, setSID] = useState('')
  const history = useHistory()

  const handleSearch = e => {
    if (sid.trim().length) {
      e.preventDefault()
      return history.push(`/sid/${sid.trim()}`)      
    }
  }

  return (
    <div className="columns is-centered">
      <div className="column is-12 is-6-desktop">
        <div className="has-text-centered">
          <figure className="image is-256x256 is-margin-auto">
            <img src={QboyImg} alt="queue"></img>
          </figure>

          <h2 className="title has-text-weight-semibold is-size-3 is-size-4-mobile">
            Find your Queue Status
          </h2>

          <div className="field has-addons has-addons-centered">
            <div className="control is-expanded">
              <input
                className="input has-fixed-size has-height-8 br-left-18px"
                component="textarea"
                placeholder="Enter Service Queue ID"
                onChange={e => setSID(e.target.value)}
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    handleSearch(e)
                  }
                }}
              />
            </div>
            <div className="control">
              <button
                className="button is-info has-font-weight-bold has-font-variant-petite-caps"
                onClick={e => {
                  handleSearch(e)
                }}
              >
                SEARCH
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
