import React from 'react'
import ReactTimeAgo from 'react-time-ago'
import OnPageVisible from '../OnPageVisible/OnPageVisible'
import RefreshOffline from '../RefreshOffline/RefreshOffline'
import { useFetchQueuerStatusData } from '../../hooks'

export const QueuerStatus = props => {
  const { queuer } = props

  return (
    <div className="content">
      <div className="is-size-4">Hi {queuer.queuer},</div>
      <div className="my-4 box is-shadowless has-border-grey">
        <div className="p-2 heading">Your Queue Status</div>
        <OnPageVisible>
          <RefreshOffline>
            <QueueStatusSection id={queuer.id} />
          </RefreshOffline>
        </OnPageVisible>
      </div>
    </div>
  )
}

const QueueStatusSection = ({ id }) => {
  const { isLoading, queuerStatus } = useFetchQueuerStatusData({
    id,
  })

  if (isLoading) {
    return <div>Loading...</div>
  }

  let statusLabel, statusTagStyle, lastUpdated

  if (queuerStatus.status === 1) {
    statusLabel = 'WAITING'
    statusTagStyle = 'is-warning'
    lastUpdated = queuerStatus.queuedOn
  } else if (queuerStatus.status === 2) {
    statusLabel = 'READY'
    statusTagStyle = 'is-success'
    lastUpdated = queuerStatus.readyOn
  } else {
    statusLabel = 'COLLECTED'
    statusTagStyle = 'is-info'
    lastUpdated = queuerStatus.collectedOn
  }

  return (
    <>
      <div className="my-4">
        <div className={`is-size-4 tag ${statusTagStyle}`}>{statusLabel}</div>
        <p className="subtitle is-size-7 has-text-grey mt-2">
          since <ReactTimeAgo date={lastUpdated} timeStyle="twitter" />
        </p>
      </div>
    </>
  )
}
