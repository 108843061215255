import React from 'react'
import { Link } from 'react-router-dom'
import { QueuerStatus } from './QueuerStatus'

export const QueuerCard = props => {
  const { queuer } = props
  
  return (
    <section className="p-2 has-text-centered">
      <div className="columns is-centered">
        <div className="column is-8 is-4-desktop">
          <h1 className="logo title is-4 p-4 has-text-centered">
            {queuer.queue.business.name}
          </h1>

          <div className="card">
            <header>
              <p className="card-header-title logo is-size-6 has-text-centered p-4">
                <Link className="is-underline" to={`/sid/${queuer.queue.code}`}>
                  {queuer.queue.name}
                </Link>
              </p>
            </header>
            <div className="card-content">
              <QueuerStatus queuer={queuer} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
