import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";

const UnexpectedErrorPage = () => {
  return (
    <div id="layout-wrapper">
      <NavBar />

      <div id="content-wrapper">
        <section className="section">
          <div className="container has-text-centered">
            <div className="columns is-centered">
              <div className="column is-7">
                <h1 className="title is-1">500</h1>
                <p className="subtitle is-3">Unexpected Error :(</p>
              </div>
            </div>
            <Link to="/" className="button is-primary mt-6">
              Back to homepage
            </Link>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default UnexpectedErrorPage;
