import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import './assets/sass/styles.sass'

import Amplify from 'aws-amplify'
import config from './aws-exports'

import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import { ErrorBoundary } from 'react-error-boundary'

import HomePage from './pages/home'
import NotFoundPage from './pages/404'
import UnexpectedErrorPage from './pages/error'
import { QueueListPage, QueuerPage } from './pages'

// import ReactGA from 'react-ga';

// Initialize Google Analytics
// ReactGA.initialize(process.env.REACT_APP_GA_CODE);

TimeAgo.addDefaultLocale(en)

// Amplify.Logger.LOG_LEVEL = 'DEBUG';
Amplify.configure({ ...config, aws_appsync_authenticationType: 'AWS_IAM' })

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary
      FallbackComponent={UnexpectedErrorPage}
      onError={err => console.error(err)}
    >
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/404" component={NotFoundPage} />

          <Route exact path="/sid/:sid" component={QueueListPage} />
          <Route exact path="/qid/:qid" component={QueuerPage} />

          <Route component={NotFoundPage} />
        </Switch>
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
)
